import classNames from "classnames";
import Image, { ImageLoader } from "next/image";
import { ReactNode } from "react";

export type AvatarSize =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "8xl"
  | "12xl"
  | "16xl";

type Props = {
  imageUrl?: string;
  imageLoader?: ImageLoader;
  text?: string;
  size?: AvatarSize;
  rounded?: boolean;
  objectCover?: boolean;
  isProfileAvatar?: boolean;
  noInitials?: boolean;
  children?: ReactNode;
};

const sizeClass = {
  xs: "h-6 w-6 text-xs",
  sm: "h-8 w-8 text-sm",
  md: "h-10 w-10 text-lg",
  lg: "h-12 w-12 text-xl",
  xl: "h-14 w-14 text-2xl",
  "2xl": "h-16 w-16 text-3xl",
  "8xl": "h-28 w-28 text-6xl",
  "12xl": "h-36 w-36 text-6xl",
  "16xl": "h-48 w-48 text-9xl",
};

const getColor = (text: string) => {
  const stringUniqueHash = text.split("").reduce((acc, char) => {
    // eslint-disable-next-line no-bitwise
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  const colors = [
    "bg-[#00CFEB]",
    "bg-[#293EDD]",
    "bg-[#FB637E]",
    "bg-[#FFC7F4]",
    "bg-[#8A2A2B]",
    "bg-[#8A2A2B]",
    "bg-[#003A40]",
    "bg-[#8F96AE]",
    "bg-[#454D63]",
    "bg-[#4EB782]",
    "bg-[#FFA34D]",
    "bg-[#E57184]",
    "bg-[#007A3D]",
    "bg-[#F57600]",
    "bg-[#D3314C]",
  ];

  return colors[Math.abs(stringUniqueHash % colors.length)];
};

const Avatar = ({
  imageUrl,
  imageLoader,
  text,
  size,
  rounded,
  objectCover,
  isProfileAvatar,
  noInitials,
  children,
}: Props) => {
  const initials = text
    ?.split(" ")
    .map((s) => s[0])
    .slice(0, 2)
    .join("");

  let aliasColors = isProfileAvatar
    ? { bgColor: "bg-brand-solid", textColor: "text-white" }
    : { bgColor: undefined, textColor: "text-white" };

  if (noInitials) {
    aliasColors = { bgColor: "bg-gray-300", textColor: "text-gray-300" };
  }

  return (
    <span className="inline-block relative">
      {!imageUrl && (
        <span
          className={classNames(
            "inline-flex items-center justify-center",
            sizeClass[size || "md"],
            { "rounded-full": rounded },
            aliasColors?.bgColor || getColor(text || "")
          )}
        >
          <p className={`font-medium ${aliasColors.textColor}`}>{initials}</p>
        </span>
      )}

      {imageUrl && (
        <div className={classNames("relative", sizeClass[size || "md"])}>
          {/* eslint-disable-next-line react/forbid-elements */}
          <Image
            className={classNames(
              { "rounded-full": rounded },
              { "object-cover": objectCover }
            )}
            src={imageUrl}
            alt={`Profile picture for ${text}`}
            fill
            loader={imageLoader || undefined}
            unoptimized
          />
        </div>
      )}

      <div className="top-1.5">{children}</div>
    </span>
  );
};

export default Avatar;
