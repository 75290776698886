// One spot where this is used
// eslint-disable-next-line no-restricted-imports
import { hotjar as hj } from "react-hotjar";

export enum Event {
  UserLogin = "user_login",
  UserSignUp = "user_signup",
  UserVerifyEmail = "user_verify_email",
  VlpFilterPricingPayment = "vlp_filter_pricing_payment",
  VdpMoreInfo = "vdp_more_info",
  VdpTestDrive = "vdp_test_drive",
  VdpVirtualWalkthrough = "vdp_virtual_walkthrough",
  VdpBookAppointment = "vdp_book_appointment",
  VdpPopupModalClose = "vdp_popup_modal_close",
  VlpPopupModalClose = "vlp_popup_modal_close",
  VdpPopupModalSubmit = "vdp_popup_modal_submit",
  VlpPopupModalSubmit = "vlp_popup_modal_submit",
  AllPagesPopupModalSubmit = "all_pages_popup_modal_submit",
  AllPagesPopupModalClose = "all_pages_popup_modal_close",
  CreditApplication = "credit_application",
  SaveSearch = "save_search",
  TradeIn = "trade_in",
  TradeInComplete = "trade_in_complete",
  TradeInFindMyVehicleStepComplete = "trade_in_find_my_vehicle_step_complete",
  TradeInBasicInfoStepComplete = "trade_in_basic_info_step_complete",
  TradeInConditionStepComplete = "trade_in_condition_step_complete",
  TradeInFinalStepComplete = "trade_in_final_step_complete",
  PurchasePersonalInfoBasicInfo = "purchase_personal_info_basic_info",
  PurchasePersonalInfoAddressMortgage = "purchase_personal_info_address_mortgage",
  PurchasePersonalInfoIncomeEmployment = "purchase_personal_info_income_employment",
  AftermarketPurchaseVehicleDetails = "aftermarket_purchase_vehicle_details",
  AftermarketPurchaseCustomerInformation = "aftermarket_purchase_customer_information",
  AftermarketPurchaseConfirmEmail = "aftermarket_purchase_confirm_email",
  AftermarketPurchaseViewSignedContract = "aftermarket_purchase_view_signed_contract",
  AftermarketPurchaseEmailSignedContract = "aftermarket_purchase_email_signed_contract",
  AftermarketPurchaseRegenerateContracts = "aftermarket_purchase_regenerate_contracts",
  AftermarketPurchaseNoCoverages = "aftermarket_purchase_no_coverages",
  AftermarketPurchaseFreeInstantQuote = "aftermarket_purchase_free_instant_quote",
  AftermarketPurchaseAvailableCoverages = "aftermarket_purchase_available_coverages",
  AftermarketPurchaseVehicleDoesntMatch = "aftermarket_purchase_vehicle_doesnt_match",
  AftermarketConvertPurchaseFreeInstantQuote = "aftermarket_convert_purchase_free_instant_quote",
  ConsumerFinancingConfirmEmail = "consumer_financing_confirm_email",
  ConsumerFinancingPersonalDetails = "consumer_financing_personal_details",
  ConsumerFinancingVehicleDetails = "consumer_financing_vehicle_details",
  BillOfSaleVehicleDetails = "bill_of_sale_vehicle_details",
  BillOfSaleBuyerSellerInfo = "bill_of_sale_buyer_seller_info",
  BillOfSaleVinDoesntMatch = "bill_of_sale_vin_doesnt_match",
  WarrantyFilter = "warranty_filter_enabled",
  PrivateBillOfSaleDownload = "private_bill_of_sale_download",
  PrivateBillOfSaleSendEmail = "private_bill_of_sale_send_email",
  KijijiIcoTradeIn = "kijiji_ico_trade_in",
  AutoCanadaIcoTradeIn = "auto_canada_ico_trade_in",
  SendLinkBackEmail = "send_link_back_email",
  WarrantySampleContract = "warranty_sample_contract",
  WarrantyBrochure = "warranty_brochure",
  PrequalificationCompletePrime = "prequalification_complete_prime",
  ProfileWarrantyProtectionResumePurchase = "profile_warranty_protection_resume_purchase",
  ProfileTradeInDelete = "profile_trade_in_delete",
  PageNotFound = "page_not_found",
  IcoAddressEntered = "ico_address_entered",
  IcoAppointmentScheduled = "ico_appointment_scheduled",
  IcoBankAccountAdded = "ico_bank_account_added",
  IcoNotFoundModalOpen = "ico_not_found_modal_open",
  IcoNotFoundModalContactUsClicked = "ico_not_found_modal_contact_us_clicked",
  IcoNotFoundModalCancelClicked = "ico_not_found_modal_cancel_clicked",
  IcoNotFoundModalClose = "ico_not_found_modal_close",
  IcoInProgressModalOpen = "ico_in_progress_modal_open",
  IcoInProgressModalClose = "ico_in_progress_modal_close",
  IcoInProgressModalContactUsClicked = "ico_in_progress_modal_contact_us_clicked",
  IcoInProgressModalCancelClicked = "ico_in_progress_modal_cancel_clicked",
  IcoKijijiLandingPageRedirect = "ico_kijiji_landing_page_redirect",
  IcoFindMyVehicleStepComplete = "ico_find_my_vehicle_step_complete",
  IcoBasicInfoStepComplete = "ico_basic_info_step_complete",
  IcoConditionStepComplete = "ico_condition_step_complete",
  IcoFinalStepComplete = "ico_final_step_complete",
  IcoBasicInfoStepVehicleUpload = "ico_basic_info_step_vehicle_upload",
  IcoConditionStepVehicleUpload = "ico_condition_step_vehicle_upload",
  IcoBasicInfoStepUploadClicked = "ico_basic_info_step_upload_clicked",
  IcoConditionStepUploadClicked = "ico_condition_step_upload_clicked",
  IcoVehicleUploadLeftSide = "ico_vehicle_upload_left_side",
  IcoVehicleUploadRear = "ico_vehicle_upload_rear",
  IcoVehicleUploadFrontInterior = "ico_vehicle_upload_front_interior",
  IcoVehicleUploadRearInterior = "ico_vehicle_upload_rear_interior",
  IcoVehicleUploadRightSide = "ico_vehicle_upload_right_side",
  IcoVehicleUploadFront = "ico_vehicle_upload_front",
  IcoVehicleUploadEngineBay = "ico_vehicle_upload_engine_bay",
  IcoVehicleUploadOdometerDash = "ico_vehicle_upload_odometer_dash",
  IcoVehicleSearchByVin = "ico_vehicle_search_by_vin",
  IcoVehicleSearchByName = "ico_vehicle_search_by_name",
}

export const hotjar = {
  event: (event: Event) => {
    if (hj.initialized()) {
      hj.event(event);
    }
  },
  identify: (userId: string | null, properties: Record<string, unknown>) => {
    if (hj.initialized()) {
      hj.identify(userId, properties);
    }
  },
  initialize: (hjid: number, hjsv: number, debug?: boolean) => {
    hj.initialize(hjid, hjsv, debug);
  },
  initialized: () => {
    return hj.initialized();
  },
};

export default {};
